/**
 * Antwort bearbeiten
 * 
 * Attila Németh, UBG
 * 26.02.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';

import {UbgAnswer} from '../../../../model/answer';
import {UbgTagService} from '../../../../services/tag.service';
import {UbgTag} from '../../../../model/tag';
import {UbgBearbeiter} from '../../../../model/bearbeiter';
import {UbgBearbeiterService} from '../../../../services/bearbeiter.service';
import {UbgAutocompleteOption} from '../../../../../ubg_components/autocomplete/model/option';

@Component({
  templateUrl: './edit.component.html',
})
export class UbgAnswerEditDialog implements OnInit {
  
  newTag: string = ''
  newBearbeiter: string = ''
  editorConfig = {
    minHeight: 5,
    toolbar: [
      ['bold', 'italic', 'underline', 'color'],
    ],
    /*
    toolbar: [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontName', 'fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['link', 'unlink', 'image', 'video']
    ],
    */
  }
  disabled: boolean = true
  hasError: boolean = false
  tagOptions: Array<UbgAutocompleteOption> = []
  
  constructor(private dialogRef: MatDialogRef<UbgAnswerEditDialog>,
    @Inject(MAT_DIALOG_DATA) private data: {
      answer: UbgAnswer,
      comment: {
        active: boolean
        text: string
      }
    },
    private snackbar: MatSnackBar,
    private tag: UbgTagService,
    private bearbeiter: UbgBearbeiterService) {
    if (data.answer.relationships.status.data === null) {
      this.data.answer.relationships.status.data = {
        attributes: {
          name: ''
        },
        id: ''
      };
    }
    this.updateDisabled();
  }
  
  ngOnInit() {
    this.tag.getAll().then((response: Array<UbgTag>) => {
      let newOptions: Array<UbgAutocompleteOption> = []
      let unsortedOptions = {};
      let sortedOptions = {};
      for (let i in response) {
        unsortedOptions[response[i].attributes.name + '--' + response[i].id] = {
          id: response[i].id,
          label: response[i].attributes.name,
        };
      }
      Object.keys(unsortedOptions).sort().forEach(function(key) {
        sortedOptions[key] = unsortedOptions[key];
      });
      for (let i in sortedOptions) {
        newOptions.push(sortedOptions[i]);
      }
      this.tagOptions = newOptions;
    });
  }
  
  activateComment() {
    this.data.comment.active = true;
  }
  
  updateDisabled() {
    this.disabled = false;
//    if (this.data.answer.relationships.status.data === null) {
//      this.disabled = true;
//    }
//    else if (this.data.answer.relationships.status.data.attributes.name == '') {
//      this.disabled = true;
//    }
//    else {
//      this.disabled = false;
//    }
  }
  
  addTag() {
    this.tag.getByTag(this.newTag).then((response: any) => {
      if (response === false) {
        this.tag.create(this.newTag).then((response: UbgTag) => {
          this._addTagToAnswer(response);
        }).catch(() => {
          setTimeout(() => {
            this.addTag();
          }, 100);
        });
      }
      else {
        this._addTagToAnswer(response);
      }
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000,
      })
    });
  }
  
  private _addTagToAnswer(tag: UbgTag) {
    let tagFound: boolean = false;
    for (let i in this.data.answer.relationships.tags.data) {
      if (this.data.answer.relationships.tags.data[i].id == tag.id) {
        tagFound = true;
      }
    }
    if (!tagFound) {
      this.data.answer.relationships.tags.data.push(tag);
    }
  }
  
  removeTag(tag: UbgTag) {
    let newTags: Array<UbgTag> = [];
    for (let i in this.data.answer.relationships.tags.data) {
      if (this.data.answer.relationships.tags.data[i].id !== tag.id) {
        newTags.push(this.data.answer.relationships.tags.data[i]);
      }
    }
    this.data.answer.relationships.tags.data = newTags;
  }
  
  addBearbeiter() {
    this.bearbeiter.getByName(this.newBearbeiter).then((response: any) => {
      if (response === false) {
        this.bearbeiter.create(this.newBearbeiter).then((response: UbgBearbeiter) => {
          this._addBearbeiterToAnswer(response);
        }).catch(() => {
          setTimeout(() => {
            this.addBearbeiter();
          }, 100);
        });
      }
      else {
        this._addBearbeiterToAnswer(response);
      }
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000,
      })
    });
  }
  
  private _addBearbeiterToAnswer(bearbeiter: UbgBearbeiter) {
    let bearbeiterFound: boolean = false;
    for (let i in this.data.answer.relationships.bearbeiter.data) {
      if (this.data.answer.relationships.bearbeiter.data[i].id == bearbeiter.id) {
        bearbeiterFound = true;
      }
    }
    if (!bearbeiterFound) {
      this.data.answer.relationships.bearbeiter.data.push(bearbeiter);
    }
    this.newBearbeiter = '';
  }
  
  removeBearbeiter(bearbeiter: UbgBearbeiter) {
    let newBearbeiters: Array<UbgBearbeiter> = [];
    for (let i in this.data.answer.relationships.bearbeiter.data) {
      if (this.data.answer.relationships.bearbeiter.data[i].id !== bearbeiter.id) {
        newBearbeiters.push(this.data.answer.relationships.bearbeiter.data[i]);
      }
    }
    this.data.answer.relationships.bearbeiter.data = newBearbeiters;
  }
  
  tagTyped(event: string) {
    this.newTag = event;
  }
  
  tagSelected(event) {
    for (let i in this.tagOptions) {
      if (this.tagOptions[i].id == event) {
        this.newTag = this.tagOptions[i].label;
      }
    }
  }
    
}