/**
 * Kommentar-Component
 * 
 * Attila Németh, UBG
 * 12.03.2019
 */
 
import {Component, OnInit, Input } from '@angular/core';

import {UbgComment} from '../../model/comment';
import {UbgCommentService} from '../../services/comment.service';
import {ContentaUserService} from '../../../contenta_user/services/contenta_user.service';

@Component({
  selector: 'ubg-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['comment.component.scss'],
})
export class UbgCommentComponent implements OnInit {
  
  @Input()comment: UbgComment
  text: string = '...'
  authorName: string = ''
  isEdit: boolean = false
  editorConfig = {
    minHeight: 5,
    toolbar: [
      ['bold', 'italic', 'underline', 'color'],
    ],
  }
  
  constructor(private service: UbgCommentService,
                private userService: ContentaUserService) {
  }
  
  ngOnInit() {
    this.load();
  }
  
  private load() {
    this.service.get(this.comment.id).then((response: UbgComment) => {
      this.comment = response;
      this.refresh();
    })
  }
  
  refresh() {
    if (this.comment.attributes !== undefined) {
      this.text = this.comment.attributes.text.value;
    }
    if (this.comment.relationships.user_id.data.attributes !== undefined) {
      this.authorName = this.comment.relationships.user_id.data.attributes.vorname + ' ' + 
        this.comment.relationships.user_id.data.attributes.nachname;
    }
  }
  
  accessEdit() {
    if (this.comment.relationships.user_id.data.id == this.userService.user.id
          && !this.isEdit) {
      return true;
    }
    else {
      return false;
    }
  }
  
  edit() {
    this.isEdit = true;
  }
  
  save() {
    this.service.getRaw(this.comment.id).then((response: UbgComment) => {
      response.attributes.text.value = this.text;
      this.service.update(response).then(() => {
        this.load();
        this.isEdit = false;
      });
    });
  }
  
  cancel() {
    this.load();
    this.isEdit = false;
  }
  
}