import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';

// UBG-Module
import {ContentaUserModule} from './modules/contenta_user/contenta_user.module';
import {UbgComponentsModule} from './modules/ubg_components/ubg_components.module';
import {UbgCampaignModule} from './modules/campaign/campaign.module';
import {UbgSidebarModule} from './modules/sidebar/sidebar.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    RouterModule,
    MatIconModule,
    ContentaUserModule,
    UbgComponentsModule,
    UbgCampaignModule,
    UbgSidebarModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
