/**
 * Kampagne
 * 
 * Attila Németh, UBG
 * 26.02.2019
 */
 
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';

import {UbgCampaignService} from '../../services/campaign.service';
import {UbgCampaign} from '../../model/campaign';
import {UbgMultipleAssignDialog} from '../dialogs/assign/assign.component';
import {UbgAnswer} from '../../model/answer';
import {UbgAnswerService} from '../../services/answer.service';
import {UbgUser} from '../../model/user';
import {UbgUserService} from '../../services/user.service';
import {UbgQuestionPrintDialog} from '../dialogs/print/print.component';

@Component({
  templateUrl: './campaign.component.html',
  styleUrls: ['campaign.component.scss'],
})
export class UbgCampaignComponent implements OnInit {
  
  search: {
    text: string
    category: string
    tag: string
    author: string
    status: string
    zustandig: string
    sort: string
  }
  refreshing: boolean = false
  refreshCounter: number = 0
  campaign: UbgCampaign
  loaded: boolean = false
  isAssigning: boolean = false
  assignCounter: {
    current: number
    all: number
  }
  
  constructor(private route: ActivatedRoute,
                private router: Router,
                private service: UbgCampaignService,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private answer: UbgAnswerService,
                private user: UbgUserService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.service.setActiveCampaignId(this.route.snapshot.paramMap.get('id'));
    this.route.queryParams.subscribe((params: {
      search_text: string
      search_category: string
      search_tag: string
      search_author: string
      search_status: string
      search_zustandig: string
      search_sort: string
    }) => {
      this.search = {
        text: '',
        category: '',
        tag: '',
        author: '',
        status: '',
        zustandig: '',
        sort: ''
      };
      if (params.search_text !== undefined) {
        this.search.text = params.search_text;
      }
      if (params.search_category !== undefined) {
        this.search.category = params.search_category;
      }
      if (params.search_tag !== undefined) {
        this.search.tag = params.search_tag;
      }
      if (params.search_author !== undefined) {
        this.search.author = params.search_author;
      }
      if (params.search_status !== undefined) {
        this.search.status = params.search_status;
      }
      if (params.search_zustandig !== undefined) {
        this.search.zustandig = params.search_zustandig;
      }
      if (params.search_sort !== undefined) {
        this.search.sort = params.search_sort;
      }
      this.refreshCampaign();
    });
    this.campaign = null;
  } 
  
  ngOnInit() {}
  
  refreshCampaign() {
    this.refreshing = true;
    this.service.refresh(this.service.activeCampaignId, this.search).then((response: UbgCampaign) => {
      this.campaign = response;
      this.refreshing = false;
      this.loaded = true;
    }).catch(() => {
      this.refreshCounter++;
      setTimeout(() => {
        this.refreshCampaign();
      }, this.refreshCounter * 100);
    });
  }
  
//  print(comments: number) {
//    this.service.print(this.campaign, comments).then((response: any) => {
//      let fileName = 'kampagne.pdf';
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, fileName);
//      } 
//      else {
//        a.href = url;
//        a.download = fileName;
//        a.click();
//      }
//    });
//  }
  
  printDialog() {
    let data = {
      kommentare: true,
      namen: true,
    };
    this.dialog.open(UbgQuestionPrintDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        const comments = data.kommentare ? 1 : 0;
        const names = data.namen ? 1 : 0;
        this.print(comments, names);
      }
    });
  }
  
  print(comments: number, names: number) { 
    this.service.print(this.campaign, comments, names).then((response: any) => {
      let fileName = 'kampagne.pdf';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch((error) => {
      console.error(error);
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 15000,
      })
    });
  }
  
  assign() {
    let data = {
      users: [],
      campaign: this.campaign
    };
    this.dialog.open(UbgMultipleAssignDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        this.isAssigning = true;
        let allAnswers: Array<UbgAnswer> = [];
        for (let qi in this.campaign.filteredQuestions) {
          for (let ai in this.campaign.filteredQuestions[qi].answers) {
            allAnswers.push(this.campaign.filteredQuestions[qi].answers[ai]);
          }
        }
        this.user.setAnswerAssign(this.campaign.id, allAnswers, data.users).then(() => {
          this.isAssigning = false;
          this.refreshCampaign();
        });
      }
    })
  }
  
  tabChange(e: {
    index: number
  }) {
    if (e.index == 0) {
      // Meine Antworten
      this.refreshCampaign();
    }
  }
 
}