/**
 * Layout-Component, mit 2:1
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'ubg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class UbgLayoutComponent implements OnInit {
  
  isMobile: boolean = false
  cssClass: string = ''
  
  constructor(private breakpoint: BreakpointObserver) {}
  
  ngOnInit() {
    this.breakpoint
      .observe([Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
        this.refreshCssClass();
      });
  }
  
  refreshCssClass() {
    let classes = [];
    if (this.isMobile) {
      classes.push('ubg-layout-mobile');
    }
    this.cssClass = classes.join(' ');
  }
  
}