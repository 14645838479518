/**
 * Autor
 * 
 * Attila Németh, UBG
 * 25.02.2019
 */

import {UbgBundesland} from './bundesland';
 
export class UbgAuthor {
  
  id: string
  type: string = 'ubg_campaign_author--ubg_campaign_author'
  attributes: {
    title: string
    name: string
    person: string
    geschlecht?: string
    alter?: number
  }
  relationships: {
    bundesland?: {
      data: UbgBundesland
    }
  }
  
}