/**
 * Frage-Service
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';
import {UbgQuestion} from '../model/question';
import {UbgCampaign} from '../model/campaign';

@Injectable({
  providedIn: 'root'
})
export class UbgQuestionService {
  
  constructor(private contenta: ContentaService) {}

  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_campaign_question/ubg_campaign_question/' + id).then((response: {
        data: UbgQuestion,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_campaign_question/ubg_campaign_question').then((response: {
        data: Array<UbgQuestion>,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getOptions(campaign: UbgCampaign) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/campaign/' + campaign.id + '/move/options').then((response: {
        options: Array<{
          id: string
          label: string
        }>
      }) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(question: UbgQuestion) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_campaign_question/ubg_campaign_question/' + question.id, {
        data: question,
      }).then((response: {
        data: UbgQuestion,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  print(campaign: UbgCampaign, question: UbgQuestion, comments: number, names: number) {
    let promise = new Promise((resolve, reject) => {
      const path = 'ubg/campaign/' + campaign.id + '/print';
      let answers = [];
      for (let i in question.relationships.antworten.data) {
        answers.push({
          id: question.relationships.antworten.data[i].id,
        })
      }
      let data = {
        comments: comments,
        names: names,
        questions: [{
          id: question.id,
          answers: answers,
        }]
      };
      this.contenta.getPostFile(path, data).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}