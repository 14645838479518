/**
 * Bundesland-Service
 * 
 * Attila Németh, UBG
 * 05.06.2019
 */
 
import {Injectable} from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';

import {UbgBundesland} from '../model/bundesland';

@Injectable({
  providedIn: 'root'
})
export class UbgBundeslandService {
  
  constructor(private contenta: ContentaService) {}
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/bundesland?sort=name').then((response: {
        data: Array<UbgBundesland>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}