/**
 * Kategorie-Service
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';

import {UbgCategory} from '../model/category';

@Injectable({
  providedIn: 'root'
})
export class UbgCategoryService {
  
  constructor(private contenta: ContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/frage_kategorien/' + id).then((response: {
        data: UbgCategory
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/frage_kategorien?sort=weight').then((response: {
        data: Array<UbgCategory>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getByTag(tag: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/frage_kategorien?filter[name]=' + tag).then((response: {
        data: Array<UbgCategory>
      }) => {
        if (response.data.length > 0) {
          resolve(response.data[0]);
        }
        else {
          resolve(false);
        }
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(tag: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/taxonomy_term/frage_kategorien', {
        data: {
          type: 'taxonomy_term--frage_kategorien',
          attributes: {
            name: tag,
          }
        }
      }).then((response: {
        data: UbgCategory,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}