/**
 * Antwort-Service
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';
import {UbgAnswer} from '../model/answer';
import {UbgCampaign} from '../model/campaign';
import {UbgEntityLog} from '../../entity_log/model/entity_log';

@Injectable({
  providedIn: 'root'
})
export class UbgAnswerService {
  
  constructor(private contenta: ContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_campaign_answer/ubg_campaign_answer/' + id).then((response: {
        data: UbgAnswer
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(answer: UbgAnswer) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_campaign_answer/ubg_campaign_answer/' + answer.id, {
        data: answer
      }).then((response: {
        data: UbgAnswer
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  print(campaign: UbgCampaign, answer: UbgAnswer, comments: number, names: number) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/campaign/' + campaign.id + '/print';
      let data = {
        comments: comments,
        names: names,
        answers: [{
          id: answer.id,
        }],
      };
      this.contenta.getPostFile(path, data).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(answer: UbgAnswer) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_campaign_answer/ubg_campaign_answer', {
        data: answer
      }).then((response: {
        data: UbgAnswer,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getLog(answerId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/campaign/answer/' + answerId + '/log').then((response: Array<UbgEntityLog>) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}