/**
 * Statistik-Element-Komponent
 * 
 * Attila Németh, UBG
 * 14.03.2019
 */
 
import {Component, OnInit, Input} from '@angular/core';

import {UbgSearch} from '../../model/search';
import {UbgCampaignService} from '../../services/campaign.service';
import {UbgStatisticItemTitle} from './model/title';

@Component({
  selector: 'ubg-campaign-statistics-item',
  templateUrl: 'statitem.component.html',
  styleUrls: ['statitem.component.scss'],
})
export class UbgStatisticsItemComponent implements OnInit {
  
  @Input()src: string
  @Input()title: string
  @Input() set search(value: UbgSearch) {
    this.searchParams = value;
    this.refresh();
  }
  searchParams: UbgSearch
  isLoading: boolean = false
  hasError: boolean = false
  titles: Array<UbgStatisticItemTitle>
  flex: string = '1 1 100%'
  showDetails: boolean = false;
  
  constructor(private service: UbgCampaignService) {}
  
  ngOnInit() {}
  
  refresh() {
    this.isLoading = true;
    this.titles = [];
    this.service.getStat(this.src, 'title', this.searchParams).then((response: {
      titles: Array<UbgStatisticItemTitle>,
    }) => {
      this.titles = response.titles;
      this.isLoading = false;
      this.hasError = false; 
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    });
  }
  
  toggleDetails() {
    if (this.showDetails) {
      this.showDetails = false;
    }
    else {
      this.showDetails = true;
    }
  }
  
}