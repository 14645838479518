/**
 * Status-Service
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';

import {UbgStatus} from '../model/status';

@Injectable({
  providedIn: 'root'
})
export class UbgStatusService {
  
  constructor(private contenta: ContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/kampagnenstatus/' + id).then((response: {
        data: UbgStatus
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/kampagnenstatus?filter[name]=' + name).then((response: {
        data: Array<UbgStatus>
      }) => {
        if (response.data.length > 0) {
          resolve(response.data[0]);
        }
        else {
          resolve(false);
        }
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/taxonomy_term/kampagnenstatus', {
        data: {
          type: 'taxonomy_term--kampagnenstatus',
          attributes: {
            name: name,
          }
        }
      }).then((response: {
        data: UbgStatus,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}