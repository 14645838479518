/**
 * Bearbeiter-Service
 * 
 * Attila Németh, UBG
 * 12.03.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';

import {UbgBearbeiter} from '../model/bearbeiter';

@Injectable({
  providedIn: 'root'
})
export class UbgBearbeiterService {
  
  constructor(private contenta: ContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/kampagnenbearbeiter/' + id).then((response: {
        data: UbgBearbeiter
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/kampagnenbearbeiter?filter[name]=' + name).then((response: {
        data: Array<UbgBearbeiter>
      }) => {
        if (response.data.length > 0) {
          resolve(response.data[0]);
        }
        else {
          resolve(false);
        }
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(tag: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/taxonomy_term/kampagnenbearbeiter', {
        data: {
          type: 'taxonomy_term--kampagnenbearbeiter',
          attributes: {
            name: tag,
          }
        }
      }).then((response: {
        data: UbgBearbeiter,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}