/**
 * Statistik-Komponent
 * 
 * Attila Németh, UBG
 * 13.03.2019
 */
 
import {Component} from '@angular/core';

import {ActivatedRoute} from '@angular/router';

import {UbgCampaignService} from '../../services/campaign.service';
import {UbgCampaign} from '../../model/campaign';

@Component({
  selector: 'ubg-campaign-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['statistics.component.scss'],
})
export class UbgStatisticsComponent {
  
  search: {
    text: string
    category: string
    tag: string
    author: string
    status: string
  }
  
  constructor(private route: ActivatedRoute,
                private service: UbgCampaignService) {
    this.service.setActiveCampaignId(this.route.snapshot.paramMap.get('id'));
    this.route.queryParams.subscribe((params: {
      search_text: string
      search_category: string
      search_tag: string
      search_author: string
      search_status: string
    }) => {
      this.search = {
        text: '',
        category: '',
        tag: '',
        author: '',
        status: '',
      };
      if (params.search_text !== undefined) {
        this.search.text = params.search_text;
      }
      if (params.search_category !== undefined) {
        this.search.category = params.search_category;
      }
      if (params.search_tag !== undefined) {
        this.search.tag = params.search_tag;
      }
      if (params.search_author !== undefined) {
        this.search.author = params.search_author;
      }
      if (params.search_status !== undefined) {
        this.search.status = params.search_status;
      }
    });
  }
  
  
}