/**
 * Alles, was mit den Kampagnen zu tun hat
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSortModule} from '@angular/material/sort';
import {MatButtonToggleModule} from '@angular/material/button-toggle';

import {FlexLayoutModule} from '@angular/flex-layout';
import {NgxEditorModule} from 'ngx-editor';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {UbgLayoutModule} from '../layout/layout.module';
import {UbgSelectEntityModule} from '../select_entity/select_entity.module';
import {UbgAutocompleteModule} from '../ubg_components/autocomplete/autocomplete.module';

import {UbgCampaignSelectComponent} from './components/select/select.component';
import {UbgCampaignComponent} from './components/campaign/campaign.component';
import {UbgQuestionComponent} from './components/question/question.component';
import {UbgQuestionCategoryDialog} from './components/question/dialogs/category/category.component';
import {UbgAnswerComponent} from './components/answer/answer.component';
import {UbgAnswerEditDialog} from './components/answer/dialogs/edit/edit.component';
import {UbgAnswerAssignDialog} from './components/answer/dialogs/assign/assign.component';
import {UbgCommentComponent} from './components/comment/comment.component';
import {UbgSearchComponent} from './components/search/search.component';
import {UbgStatisticsComponent} from './components/statistics/statistics.component';
import {UbgStatisticsItemComponent} from './components/statitem/statitem.component';
import {UbgStatisticsItemTitleComponent} from './components/statitem/components/title/title.component';
import {UbgStatisticsItemDetailsComponent} from './components/statitem/components/details/details.component';
import {UbgAnswerMoveDialog} from './components/answer/dialogs/move/move.component';
import {UbgMultipleAssignDialog} from './components/dialogs/assign/assign.component';
import {UbgQuestionAssignDialog} from './components/question/dialogs/assign/assign.component';
import {UbgAnswerDialog} from './components/question/dialogs/answer/answer.component';
import {UbgQuestionPrintDialog} from './components/dialogs/print/print.component';
import {UbgAnswerLogDialogComponent} from './components/answer/dialogs/log/log.component';
import {UbgAnswerDeleteDialog} from './components/dialogs/delete/delete.component';

const campaignRoutes: Routes = [
  {
    path: 'campaign/:id',
    component: UbgCampaignComponent,
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(
      campaignRoutes,
      {
        enableTracing: false,
        onSameUrlNavigation: 'reload',
      }
    ),
    FormsModule,
    HttpClientModule,
    MatSelectModule,
    MatTabsModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatCardModule,
    MatCheckboxModule,
    MatSortModule,
    MatButtonToggleModule,
    FlexLayoutModule,
    NgxEditorModule,
    TooltipModule.forRoot(),
    UbgLayoutModule,
    UbgSelectEntityModule,
    UbgAutocompleteModule,
  ],
  declarations: [
    UbgCampaignSelectComponent,
    UbgCampaignComponent,
    UbgQuestionComponent,
    UbgQuestionCategoryDialog,
    UbgAnswerComponent,
    UbgAnswerEditDialog,
    UbgAnswerAssignDialog,
    UbgCommentComponent,
    UbgSearchComponent,
    UbgStatisticsComponent,
    UbgStatisticsItemComponent,
    UbgStatisticsItemTitleComponent,
    UbgStatisticsItemDetailsComponent,
    UbgAnswerMoveDialog,
    UbgMultipleAssignDialog,
    UbgQuestionAssignDialog,
    UbgAnswerDialog,
    UbgQuestionPrintDialog,
    UbgAnswerLogDialogComponent,
    UbgAnswerDeleteDialog,
  ],
  exports: [
    UbgCampaignSelectComponent,
    UbgCampaignComponent,
  ],
  entryComponents: [
    UbgQuestionCategoryDialog,
    UbgAnswerEditDialog,
    UbgAnswerAssignDialog,
    UbgAnswerMoveDialog,
    UbgMultipleAssignDialog,
    UbgQuestionAssignDialog,
    UbgAnswerDialog,
    UbgQuestionPrintDialog,
    UbgAnswerLogDialogComponent,
    UbgAnswerDeleteDialog
  ],
})
export class UbgCampaignModule {

  constructor() {}

}