import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import {ContentaUserService} from './modules/contenta_user/services/contenta_user.service';
import {UbgSidebarService} from './modules/sidebar/services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  
  activeTab = 0;
  
  constructor(private router: Router, 
                private user: ContentaUserService,
                private sidebar: UbgSidebarService) {}
  
  getTabClass(tab: number) {
    if (tab === this.activeTab) {
      return 'active primary-foreground';
    }
    else {
      return 'primary-background';
    }
  }
  
  contentVisible() {
    if (this.user.loggedIn) {
      return true;
    }
    if (this.router.url.indexOf('/user/reset') == 0) {
      return true;
    }
    if (this.router.url.indexOf('/cduplus/auth') == 0) {
      return true;
    }
    return false;
  }
  
  sidebarClose() {
  }
  
}
