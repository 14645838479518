/**
 * Environment
 * Attila Németh, UBG
 * 23.10.2018
 */
 
export const environment = {
  production: false,
  contenta: {
    clientId: '4a5f2c19-96c0-47db-bf0b-ba770a3288bf',
    clientSecret: 'ubg$123',
    baseUrl: 'https://contenta.ubgnet.de/',
    tokenUrl: 'https://contenta.ubgnet.de/oauth/token',
  },
  site: {
    name: 'Kampagnentool',
    url: 'https://paka.ubgnet.de/',
  },
  cduplus: {
    clientId: 'campaign',
    clientSecret: 'test$123',
  }
};
