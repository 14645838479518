/**
 * Select-Komponent
 * 
 * Attila Németh, UBG
 * 19.03.2019
 */
 
import {Component, Input, forwardRef} from '@angular/core';
import {Router} from '@angular/router';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms"

import {UbgSelectEntity} from '../../model/entity';

const noop = () => {
};

@Component({
  selector: 'ubg-select-entity',
  templateUrl: './select.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UbgSelectEntityComponent),
    multi: true
  }]
})
export class UbgSelectEntityComponent {
  
  @Input()entities: Array<UbgSelectEntity> = []
  @Input()placeholder: string = 'Bitte wählen'
  selectedEntityPath: string
   
  constructor(private router: Router) {}
  
  selectEntity() {
    this.router.navigate([this.selectedEntityPath]);
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  get value(): string {
    return this.selectedEntityPath;
  };

  set value(v: string) {
    if (v !== this.selectedEntityPath) {
      this.selectedEntityPath = v;
      this.onChangeCallback(v); 
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.selectedEntityPath) {
      this.selectedEntityPath = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  
}