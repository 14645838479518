/**
 * Drucken-Dialog
 * 
 * Attila Németh, UBG
 * 12.08.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
})
export class UbgQuestionPrintDialog {
  
  constructor(@Inject(MAT_DIALOG_DATA) private data: {
      kommentare: boolean,
      namen: boolean,
    }) {}
  
}