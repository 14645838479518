/**
 * UBG Left Column 
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import {Component} from '@angular/core';

@Component({
  selector: 'ubg-right-column',
  templateUrl: './right_column.component.html',
})
export class UbgRightColumnComponent {
}