/**
 * Kommentar-Service
 * 
 * Attila Németh, UBG
 * 28.02.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';
import {UbgComment} from '../model/comment';

@Injectable({
  providedIn: 'root'
})
export class UbgCommentService {
  
  constructor(private contenta: ContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      const path: string = 'ubg/comment/' + id + '/api';
      
      this.contenta.get(path).then((response: {
        data: UbgComment,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getRaw(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_comment/ubg_comment/' + id).then((response: {
        data: UbgComment,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(comment: {
    attributes: {
      text: {
        value: string
        format?: string
      }
    }
  }) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('api/ubg_comment/ubg_comment', {
        data: {
          type: 'ubg_comment--ubg_comment',
          attributes: comment.attributes,
        }
      }).then((response: {
        data: UbgComment,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(comment: UbgComment) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('api/ubg_comment/ubg_comment/' + comment.id, {
        data: comment
      }).then((response: {
        data: UbgComment,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}