/**
 * Frage-Model
 * 
 * Attila Németh, UBG
 * 22.02.2019
 */
 
import {UbgCategory} from './category';
import {UbgAnswer} from './answer';
 
export class UbgQuestion {
  
  id: string
  attributes: {
    title: string
  }
  relationships: {
    kategorie: {
      data: Array<UbgCategory>
    }
    antworten: {
      data: Array<UbgAnswer>
    }
  }
  
}