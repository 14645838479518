/**
 * Kommentar
 * 
 * Attila Németh, UBG
 * 26.02.2019
 */
 
export class UbgComment {
  
  id: string
  attributes: {
    text: {
      value: string
      format: string
      processed: string
    }
  }
  relationships: {
    user_id: {
      data: {
        id: string
        attributes: {
          name: string
          nachname: string
          vorname: string
        }
      }
    }
  }
  
}