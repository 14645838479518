/**
 * Feld-Log-Komponent
 * 
 * Attila Németh, UBG
 * 13.08.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';

import {UbgAnswerService} from '../../.././../services/answer.service';
import {UbgEntityLog} from '../../../../../entity_log/model/entity_log';
import {UbgEntityLogEntry} from '../../../../../entity_log/model/entity_log';

@Component({
  templateUrl: './log.component.html',
})
export class UbgAnswerLogDialogComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) private data: {
      entityId: string
      fieldName: string
    },
    private service: UbgAnswerService) {
  }
  
  logEntries: Array<UbgEntityLogEntry>
  isLoading: boolean = false
  
  ngOnInit() {
    this.loadLog();
  }
  
  loadLog() {
    this.isLoading = true;
    this.service.getLog(this.data.entityId).then((response: Array<UbgEntityLog>) => {
      let newEntries: Array<UbgEntityLogEntry> = [];
      for (let i in response) {
        if (response[i].fieldName == this.data.fieldName) {
          for (let j in response[i].log) {
            newEntries.push(response[i].log[j]);
          }
        }
      }
      this.logEntries = newEntries;
      this.isLoading = false;
    });
  }
  
}