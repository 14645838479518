/**
 * Kampagne-Service
 * 
 * Attila Németh, UBG
 * 26.02.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';
import {UbgCampaign} from '../model/campaign';

@Injectable({
  providedIn: 'root'
})
export class UbgCampaignService {
  
  activeCampaignId: string = '';
  
  constructor(private contenta: ContentaService) {}
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_campaign/ubg_campaign').then((response: {
        data: Array<UbgCampaign>
      }) => {
        resolve(response.data);
      }).catch((error: {
          status: string
        }) => {
        reject(error.status);
      });
    });
    return promise;
  }
  
  setActiveCampaignId(id: string) {
    this.activeCampaignId = id;
  }
  
  updateCampaign() {
    let promise = new Promise((resolve, reject) => {
      let requestId = Math.round(Math.random() * 1000000).toString();
      this.updateCampaignRequest(requestId).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  updateCampaignRequest(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/campaign/' + this.activeCampaignId + '/update/' + id, {}).then(() => {
        resolve();
      }).catch((error) => {
        setTimeout(() => {
          this.updateCampaignRequest(id).then(() => {
            resolve();
          });
        }, 10000);
      });
    });
    return promise;
  }
  
  refresh(id: string, search: {
    text: string
    tag: string
  }) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/campaign/' + this.activeCampaignId + '/refresh', search).then((response: UbgCampaign) => {
        resolve(response);
      }).catch((error) => {
        reject();
      });
    });
    return promise;
  }
  
  getStat(src: string, type: string, search: {
    text?: string
    category?: string
    tag?: string
    author?: string
    status?: string
  }) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'ubg/campaign/' + this.activeCampaignId + '/stat/' + src + '/' + type;
      this.contenta.post(path, search).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
//  print(campaign: UbgCampaign, comments: number) {
//    let promise = new Promise((resolve, reject) => {
//      let path = 'ubg/campaign/' + campaign.id + '/print/' + comments;
//      let answers = [];
//      let data = {
//        questions: campaign.filteredQuestions,
//      };
//      this.contenta.getPostFile(path, data).then((response) => {
//        resolve(response);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
  
  print(campaign: UbgCampaign, comments: number, names: number) {
    let promise = new Promise((resolve, reject) => {
      const path = 'ubg/campaign/' + campaign.id + '/print';
      let questions = [];
      for (let i in campaign.filteredQuestions) {
        questions.push({
          id: campaign.filteredQuestions[i].id,
        });
      }
      let data = {
        comments: comments,
        names: names,
        questions: questions,
      };
      this.contenta.getPostFile(path, data).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}