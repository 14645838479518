/**
 * CDU-Gliederung-Service
 * 
 * Attila Németh, UBG
 * 06.03.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';
import {CduGliederung} from '../model/cdu_gliederung';

@Injectable({
  providedIn: 'root'
})
export class CduGliederungService {
  
  constructor(private contenta: ContentaService) { 
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_cdu_gliederung/ubg_cdu_gliederung').then((response: {
        data: Array<CduGliederung>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}