/**
 * Suche-Model
 * 
 * Attila Németh, UBG
 * 14.03.2019
 */
 
export class UbgSearch {
  
  text?: string
  category?: string
  tag?: string
  author?: string
  status?: string
  
}