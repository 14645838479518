/**
 * Löschen-Dialog
 * 
 * Attila Németh, UBG
 * 26.11.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  templateUrl: './delete.component.html',
})
export class UbgAnswerDeleteDialog {
  
  constructor() {}
  
}