/**
 * Kampagne auswählen
 * 
 * Attila Németh, UBG
 * 26.02.2019
 */
 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

import {MatSnackBar} from '@angular/material';

import {UbgCampaignService} from '../../services/campaign.service';
import {UbgCampaign} from '../../model/campaign';
import {UbgSearchService} from '../../services/search.service';

@Component({
  selector: 'ubg-campaign-select',
  templateUrl: './select.component.html',
  styleUrls: ['select.component.scss'],
})
export class UbgCampaignSelectComponent implements OnInit {
  
  campaigns: Array<UbgCampaign> = []
  loadCounter: number = 0
  campaignOptions: Array<{
    path: string
    label: string
  }>
  isUpdating: boolean = false
  isDenied: boolean = false
  isFilterVisible: boolean = false
  
  constructor(private router: Router,
                private breakpoint: BreakpointObserver,
                private snackbar: MatSnackBar,
                private searchVisible: UbgSearchService,
                private service: UbgCampaignService) {
    this.breakpoint
      .observe([Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isFilterVisible = true;
        } else {
          this.isFilterVisible = false;
          this.searchVisible.hide();
        }
    });
  }
  
  ngOnInit() {
    this.loadCampaigns();
  }
  
  loadCampaigns() {
    this.service.getAll().then((response: Array<UbgCampaign>) => {
      if (response.length == 0) {
        this.snackbar.open('Die Kampagnen können nicht geladen werden', '', {
          duration: 8000,
        });
      }
      this.campaigns = response;
      this.campaignOptions = [];
      let newOptions = [];
      for (let i in response) {
        newOptions.push({
          path: 'campaign/' + response[i].id,
          label: response[i].attributes.title,
        });
      }
      this.campaignOptions = newOptions;
    }).catch((error) => {
      this.loadCounter++;
      if (this.loadCounter <= 10) {
        setTimeout(() => {
          this.loadCampaigns();
        }, this.loadCounter * 250);
      }
      else {
        if (error == 403) {
          this.isDenied = true;
        }
        this.snackbar.open('Die Kampagnen können nicht geladen werden', '', {
          duration: 15000,
        });
      }
    });
  }
  
  selectCampaign(e: {
    value: string
  }) {
    for (let i in this.campaigns) {
      if (this.campaigns[i].id == e.value) {
        this.service.setActiveCampaignId(this.campaigns[i].id);
        this.router.navigate(['campaign/' + this.campaigns[i].id]);
      }
    }
  }

  updateCampaign() {
    this.isUpdating = true;
    this.service.updateCampaign().then(() => {
      this.snackbar.open('Die Kampagne wurde aktualisiert', '', {
        duration: 5000,
      });
      this.isUpdating = false;
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000,
      });
      this.isUpdating = false;
    });
  }
  
  getFilterClass() {
    if (this.searchVisible.isVisible) {
      return 'active';
    }
    else {
      return '';
    }
  }
 
}


