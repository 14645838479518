/**
 * Suche-Komponent
 * 
 * Attila Németh, UBG
 * 12.03.2019
 */
 
import {Component, OnInit, Input} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {UbgCampaignService} from '../../services/campaign.service';
import {UbgCategory} from '../../model/category';
import {UbgCategoryService} from '../../services/category.service';
import {UbgTag} from '../../model/tag';
import {UbgTagService} from '../../services/tag.service';
import {UbgAuthor} from '../../model/author';
import {UbgAuthorService} from '../../services/author.service';
import {UbgUser} from '../../model/user';
import {UbgUserService} from '../../services/user.service';
import {UbgCampaign} from '../../model/campaign';
import {UbgSearchService} from '../../services/search.service';
import {UbgAutocompleteOption} from '../../../ubg_components/autocomplete/model/option';

@Component({
  selector: 'ubg-campaign-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class UbgSearchComponent implements OnInit {
  
  @Input() set campaign(value: UbgCampaign) {
    if (value !== null && value.id !== undefined) {
      this.loadUserOptions(value);
    }
  }
  @Input() sortEnabled: boolean = false
  search: {
    text: string
    category: string
    tag: string
    authorName: string
    authorId: string
    status: string
    zustandig: string
    sort: string
  }
  categoryOptions: Array<UbgCategory>
  tagOptions: Array<UbgTag>
  authorOptions: Array<UbgAutocompleteOption>
  zustandigOptions: Array<UbgUser>
  filteredZustandigOptions: Array<UbgUser>
  selectedZustandigName: string
  selectedZustandigNone: boolean = false
  isVisible: boolean = true
  
  constructor(private router: Router,
                private route: ActivatedRoute,
                private breakpoint: BreakpointObserver,
                private searchVisible: UbgSearchService,
                private service: UbgCampaignService,
                private category: UbgCategoryService,
                private tag: UbgTagService,
                private author: UbgAuthorService,
                private user: UbgUserService) {
    this.categoryOptions = [];
    this.tagOptions = [];
    this.authorOptions = [];
    this.zustandigOptions = [];
    this.route.queryParams.subscribe((params: {
      search_text: string
      search_category: string
      search_tag: string
      search_author: string
      search_status: string
      search_zustandig: string
      search_sort: string
    }) => {
      this.search = {
        text: '',
        category: '',
        tag: '',
        authorName: '',
        authorId: '',
        status: '',
        zustandig: '',
        sort: 'default',
      };
      if (params.search_text !== undefined) {
        this.search.text = params.search_text;
      }
      if (params.search_category !== undefined) {
        this.search.category = params.search_category;
      }
      if (params.search_tag !== undefined) {
        this.search.tag = params.search_tag;
      }
      if (params.search_author !== undefined) {
        this.search.authorId = params.search_author;
        this.author.get(this.search.authorId).then((response: UbgAuthor) => {
          this.search.authorName = response.attributes.title;
        });
      }
      if (params.search_status !== undefined) {
        this.search.status = params.search_status;
      }
      if (params.search_zustandig == '-1') {
        this.selectedZustandigNone = true;
      }
      else if (params.search_zustandig !== undefined) {
        this.search.zustandig = params.search_zustandig;
      }
      if (params.search_sort !== undefined) {
        this.search.sort = params.search_sort;
      }
    });
    this.breakpoint
      .observe([Breakpoints.XSmall])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isVisible = false;
        } else {
          this.isVisible = true;
        }
      });
  }
  
  ngOnInit() {
    this.loadOptions();
  }
  
  loadOptions() {
    this.category.getAll().then((response: Array<UbgCategory>) => {
      this.categoryOptions = response;
      for(let i in response) {
        if (response[i].id == this.search.category) {
          this.search.category = response[i].attributes.name;
        }
      }
    });
    this.tag.getAll().then((response: Array<UbgTag>) => {
      this.tagOptions = response;
      for(let i in response) {
        if (response[i].id == this.search.tag) {
          this.search.tag = response[i].attributes.name;
        }
      }
    });
//    this.author.getAll().then((response: Array<UbgAuthor>) => {
//      let newOptions: Array<UbgAutocompleteOption> = [];
//      let unsortedOptions = {};
//      let sortedOptions = {};
//      for(let i in response) {
//        unsortedOptions[response[i].attributes.title + '--' + response[i].id] = {
//          id: response[i].id,
//          label: response[i].attributes.title,
//        };
//        if (response[i].id == this.search.author) {
//          this.search.author = response[i].attributes.title;
//        }
//      }
//      Object.keys(unsortedOptions).sort().forEach(function(key) {
//        sortedOptions[key] = unsortedOptions[key];
//      });
//      for (let i in sortedOptions) {
//        newOptions.push(sortedOptions[i]);
//      }
//      this.authorOptions = newOptions;
//    });
  }
  
  loadUserOptions(campaign: UbgCampaign) {
    this.user.getAllAssignables(campaign.id).then((response: Array<UbgUser>) => {
      this.zustandigOptions = response;
      this.filteredZustandigOptions = response;
      for(let i in response) {
        if (response[i].id == this.search.zustandig) {
          this.selectedZustandigName = response[i].attributes.nachname + ', ' + response[i].attributes.vorname;
        }
      }
    });
  }
  
  applySearch() {
    let params: {
      search_text?: string
      search_category?: string
      search_tag?: string
      search_author?: string
      search_status?: string
      search_zustandig?: string
      search_sort: string
    } = {
      search_sort: 'standard',
    };
    if (this.search.text !== '') {
      params.search_text = this.search.text;
    }
    if (this.search.category !== '') {
      for (let i in this.categoryOptions) {
        if (this.categoryOptions[i].attributes.name == this.search.category) {
          params.search_category = this.categoryOptions[i].id;
        }
      }
    }
    if (this.search.tag !== '') {
      for (let i in this.tagOptions) {
        if (this.tagOptions[i].attributes.name == this.search.tag) {
          params.search_tag = this.tagOptions[i].id;
        }
      }
    }
    if (this.search.authorId !== '') {
      params.search_author = this.search.authorId;
    }
    if (this.search.status !== '') {
      params.search_status = this.search.status;
    }
    if (this.search.zustandig !== '' && !this.selectedZustandigNone) {
      params.search_zustandig = this.search.zustandig;
    }
    else if (this.selectedZustandigNone) {
      params.search_zustandig = '-1';
    }
    if (this.search.sort !== '') {
      params.search_sort = this.search.sort;
    }
    this.router.navigate(['campaign/' + this.service.activeCampaignId], { 
      queryParams: params 
    });
    this.loadOptions(); 
  }
  
  resetSearch() {
    this.search = {
      text: '',
      category: '',
      tag: '',
      authorName: '',
      authorId: '',
      status: '',
      zustandig: '',
      sort: '',
    };
    this.selectedZustandigName = '';
    this.selectedZustandigNone = false;
    this.loadOptions();
    this.router.navigate(['campaign/' + this.service.activeCampaignId]);
  }
  
  updateFilteredZustandigOptions() {
    let newOptions = [];
    for (let i in this.zustandigOptions) {
      if (this.zustandigOptions[i].attributes.name.toLocaleLowerCase().indexOf(this.selectedZustandigName.toLowerCase()) >= 0
        || this.zustandigOptions[i].attributes.vorname.toLocaleLowerCase().indexOf(this.selectedZustandigName.toLowerCase()) >= 0
        || this.zustandigOptions[i].attributes.nachname.toLocaleLowerCase().indexOf(this.selectedZustandigName.toLowerCase()) >= 0) {
        newOptions.push(this.zustandigOptions[i]);
      }
    } 
    this.filteredZustandigOptions = newOptions;
  }
  
  zustandigSelected() {
    let id = JSON.parse(JSON.stringify(this.selectedZustandigName));
    this.search.zustandig = id;
    for (let i in this.zustandigOptions) {
      if (this.zustandigOptions[i].id == this.search.zustandig) {
        this.selectedZustandigName = this.zustandigOptions[i].attributes.nachname + ', ' + this.zustandigOptions[i].attributes.vorname;
      }
    }
  }
  
//  authorSelected(event: string) {
//    this.search.author = event;
//  }
  
  authorChanged() {
    if (this.search.authorName.length > 2) {
      this.author.getByName(this.search.authorName).then((response: Array<UbgAuthor>) => {
        let newOptions: Array<UbgAutocompleteOption> = [];
        for (let i in response) {
          let label: string = response[i].attributes.title;
          newOptions.push({
            id: response[i].id,
            label: label
          });
        }
        this.authorOptions = newOptions;
      });
    }
  }
  
  authorSelected(event: MatAutocompleteSelectedEvent) {
    this.author.get(event.option.value).then((response: any) => {
      this.search.authorName = response['attributes']['title'];
      this.search.authorId = response['id'];
      this.applySearch();
    });
  }
 
}