/**
 * Autor-Service
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';

import {UbgAuthor} from '../model/author';

@Injectable({
  providedIn: 'root'
})
export class UbgAuthorService {
  
  constructor(private contenta: ContentaService) {}
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_campaign_author/ubg_campaign_author/' + id).then((response: {
        data: UbgAuthor
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_campaign_author/ubg_campaign_author').then((response: {
        data: Array<UbgAuthor>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/author/search/' + name).then((response: {
        data: Array<UbgAuthor>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(author: UbgAuthor) {
    let promise = new Promise((resolve, reject) => {
      if (author.attributes.name === undefined && author.attributes.title !== undefined) {
        author.attributes.name == author.attributes.title;
      }
      this.contenta.post('api/ubg_campaign_author/ubg_campaign_author', {
        data: author
      }).then((response: {
        data: UbgAuthor,
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }

}