/**
 * Gefilterte Frage
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import {UbgAnswer} from '../model/answer';
 
export class UbgFilteredQuestion {
  
  id: string
  attributes: {
    title: string
  }
  answers: Array<UbgAnswer>
  
}