/**
 * Statstik-Tabelle-Komponent
 * 
 * Attila Németh, UBG
 * 14.03.2019
 */
 
import {Component, Input} from '@angular/core';

import {UbgSearch} from '../../../../model/search';
import {UbgCampaignService} from '../../../../services/campaign.service';

@Component({
  selector: 'ubg-campaign-statistics-item-details',
  templateUrl: 'details.component.html',
  styleUrls: ['details.component.scss'],
})
export class UbgStatisticsItemDetailsComponent {
  
  @Input()src: string
  @Input()title: string
  @Input() set search(value: UbgSearch) {
    this.searchParams = value;
    this.refresh();
  }
  searchParams: UbgSearch
  isLoading: boolean = false
  hasError: boolean = false
  tableData: {
    header: Array<{
      def: string
      label: string
      class: string
    }>
    rows: Array<any>
  }
  origRows: Array<any>
  displayedColumns: Array<string>
  
  constructor(private service: UbgCampaignService) {
    this.tableData = {
      header: [],
      rows: [],
    };
    this.displayedColumns = [];
  }
  
  refresh() {
    this.tableData = {
      header: [],
      rows: [],
    };
    this.isLoading = true;
    this.hasError = false;
    this.displayedColumns = [];
    this.service.getStat(this.src, 'details', this.searchParams).then((response: {
      header: Array<{
        def: string
        label: string
        class: string
      }>
      rows: Array<any>
    }) => {
      this.isLoading = false;
      this.hasError = false;
      this.tableData = response;
      this.origRows = response.rows;
      for (let i in this.tableData.header) {
        this.displayedColumns.push(this.tableData.header[i].def);
      }
    }).catch(() => {
      this.isLoading = false;
      this.hasError = true;
    })
  }
  
  sortData(event: {
    active: string
    direction: string
  }) {
    if (event.direction == '') {
      this.tableData.rows = this.origRows;
      return;
    }
    let newData = {};
    let type: string
    for (let i in this.tableData.header) {
      if (this.tableData.header[i]['def'] == event.active) {
        type = this.tableData.header[i]['class'];
      }
    }
    for (let i in this.origRows) {
      let key: string = 'R' + i;
      if (this.origRows[i][event.active] !== undefined) {
        if (type == 'numeric') {
          let n = Number(this.origRows[i][event.active] + 1) * 100000 + Number(i);
          key = 'N' + ('000000' + n.toString()).slice(-10);
        }
        else {
          key = this.origRows[i][event.active] + '--' + i;
        }
      }
      newData[key] = this.origRows[i];
    }
    let sortedData = [];
    if (event.direction == 'asc') {
      Object.keys(newData).sort().forEach(function(key) {
        sortedData.push(newData[key]);
      });
    }
    else if (event.direction == 'desc') {
      Object.keys(newData).sort().reverse().forEach(function(key) {
        sortedData.push(newData[key]);
      });
    }
    this.tableData.rows = sortedData;
  }
  
}