/**
 * Benutzer-Service
 * 
 * Attila Németh, UBG
 * 18.03.2019
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from '../../contenta/services/contenta.service';

import {UbgUser} from '../model/user';
import {UbgAnswer} from '../model/answer';

@Injectable({
  providedIn: 'root'
})
export class UbgUserService {
  
  constructor(private contenta: ContentaService) {}
  
//  get(id: string) {
//   let promise = new Promise((resolve, reject) => {
//      this.contenta.get('api/user/user/' + id).then((response: {
//        data: UbgUser
//      }) => {
//        resolve(response.data);
//      }).catch(() => {
//        reject();
//      })
//    });
//    return promise; 
//  }
  
  /*
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/user/user').then((response: {
        data: Array<UbgUser>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      })
    });
    return promise;
  }
  */
  
  getAssignable(campaignId: string, id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/campaign/' + campaignId + '/assignable/' + id).then((response: {
        data: UbgUser
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      })
    });
    return promise;
  }
  
  getAllAssignables(campaignId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/campaign/' + campaignId + '/assignables').then((response: {
        data: Array<UbgUser>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      })
    });
    return promise;
  }
  
  setAnswerAssign(campaignId: string, answers: Array<UbgAnswer>, users: Array<UbgUser>) {
    let promise = new Promise((resolve, reject) => {
      let data: {
        answers: Array<string>
        users: Array<string>
      } = {
        answers: [],
        users: []
      };
      for (let i in answers) {
        data.answers.push(answers[i].id);
      }
      for (let i in users) {
        data.users.push(users[i].id);
      }
      this.contenta.post('ubg/campaign/' + campaignId + '/assignable/set', data).then((response: any) => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
    
}