/**
 * Benutzer One Time Login
 * Attila Németh, UBG
 * 24.10.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {ContentaUserDialogPasswortComponent} from '../dialog/password/password';
import {ContentaService} from '../../../contenta/services/contenta.service';

@Component({
  template: '',
})
export class ContentaUserResetComponent implements OnInit {
  
  constructor(private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private contenta: ContentaService) {
  }
  
  ngOnInit() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      userId: parseInt(this.route.snapshot.paramMap.get('userId')),
      timestamp: parseInt(this.route.snapshot.paramMap.get('timestamp')),
      hash: this.route.snapshot.paramMap.get('hash'),
      email: '',
      password1: '',
      password2: '',
    };
    setTimeout(() => {
      let formDialog = this.dialog.open(ContentaUserDialogPasswortComponent, dialogConfig);
      formDialog.afterClosed().subscribe((response) => {
        if (response == 1) {
          this.contenta.postAnonymous('ubg/user/reset', dialogConfig.data).then((response: {
            status: string
          }) => {
            if (response.status == 'OK') {
              this.snackbar.open('Das neue Passwort wurde gespeichert', '', {
                duration: 5000,
              });
              this.router.navigate([]);
            }
          }).catch((error) => {
            this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal, später', '', {
              duration: 8000,
            });
          });
        }
      });
    });
  }
  
}