/**
 * Statistik-Element-Titel-Komponent
 * 
 * Attila Németh, UBG
 * 14.03.2019
 */
 
import {Component, Input} from '@angular/core';

import {UbgStatisticItemTitle} from '../../model/title';

@Component({
  selector: 'ubg-campaign-statistics-item-title',
  templateUrl: 'title.component.html',
  styleUrls: ['title.component.scss'],
})
export class UbgStatisticsItemTitleComponent {
  
  @Input()title: UbgStatisticItemTitle
  
  constructor() {}
  
}