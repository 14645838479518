/**
 * Select-Entity-Modul
 * 
 * Attila Németh, UBG
 * 19.03.2019
 */
 
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {MatSelectModule} from '@angular/material/select';

import {UbgSelectEntityComponent} from './components/select/select.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatSelectModule
  ],
  declarations: [
    UbgSelectEntityComponent,
  ],
  exports: [
    UbgSelectEntityComponent,
  ],
  entryComponents: [
  ],
})
export class UbgSelectEntityModule {

  constructor() {}

}