/**
 * Statistik-Element-Titeln
 * 
 * Attila Németh, UBG
 * 14.03.2019
 */
 
export class UbgStatisticItemTitle {
  
  label: string
  count: number
  class: string
  
}