/**
 * Kampagne
 * 
 * Attila Németh, UBG
 * 21.02.2019
 */

import {UbgFilteredQuestion} from './filteredquestion';
import {UbgQuestion} from './question';
 
export class UbgCampaign {
  
  id: string
  attributes: {
    service_url: {
      uri: string
    }
    title: string
  }
  filteredQuestions?: Array<UbgFilteredQuestion>
  assignedQuestions?: Array<UbgFilteredQuestion>
  relationships: {
    fragen: {
      data: Array<UbgQuestion>
    }
  }
  
}