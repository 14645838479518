/**
 * Zuständigkeit
 * 
 * Attila Németh, UBG
 * 18.03.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {UbgAnswer} from '../../../../model/answer';
import {UbgCampaign} from '../../../../model/campaign';
import {UbgUser} from '../../../../model/user';
import {UbgUserService} from '../../../../services/user.service';
import {UbgAutocompleteOption} from '../../../../../ubg_components/autocomplete/model/option';

@Component({
  templateUrl: './assign.component.html',
})
export class UbgAnswerAssignDialog implements OnInit {
  
  personOptions: Array<UbgUser>
  personFilteredOptions: Array<UbgAutocompleteOption> = []
  searchString: string = ''
  
  constructor(private dialogRef: MatDialogRef<UbgAnswerAssignDialog>,
    @Inject(MAT_DIALOG_DATA) private data: {
      answer: UbgAnswer,
      campaign: UbgCampaign,
      searchString: string
      users: Array<UbgUser>,
    },
    private service: UbgUserService) {
    this.personOptions = [];
  }
  
  ngOnInit() {
    this.loadPersons();
  }
  
  loadPersons() {
    this.service.getAllAssignables(this.data.campaign.id).then((response: Array<UbgUser>) => {
      let newOptions = [];
      for (let i in response) {
        newOptions.push(response[i]);
      }
      this.personOptions = newOptions;
      let freeOptions: Array<UbgAutocompleteOption> = [];
      for (let i in response) {
        let isFree: boolean = true;
        for (let j in this.data.users) {
          if (response[i].id == this.data.users[j].id) {
            isFree = false;
          }
        }
        if (isFree) {
          freeOptions.push({
            id: response[i].id,
            label: response[i].attributes.nachname + ', ' + response[i].attributes.vorname,
          });
        }
      }
      this.personFilteredOptions = freeOptions;
      this.searchString = '';
    }).catch(() => {
      console.warn('Ein unerwarteter Fehler ist aufgetreten');
      this.personOptions = [];
    });
  }
  
  getFullName(user: UbgUser): string {
    for (let i in this.personOptions) {
      if (this.personOptions[i].id == user.id) {
        return this.personOptions[i].attributes.nachname + ', ' + this.personOptions[i].attributes.vorname;
      }
    }
    return '...';
  }
  
  removeUser(user: UbgUser): void {
    let newUsers: Array<UbgUser> = [];
    for (let i in this.data.users) {
      if (this.data.users[i].id !== user.id) {
        newUsers.push(this.data.users[i]);
      }
    }
    this.data.users = newUsers;
  }
  
  userSelected(userId: string) {
    for (let i in this.personOptions) {
      if (this.personOptions[i].id == userId) {
        this.searchString = this.personOptions[i].attributes.name;
        this.data.users.push(this.personOptions[i]);
        this.loadPersons();
      }
    }
  }
    
}