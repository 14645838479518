/**
 * Schieben-Dialog
 * 
 * Attila Németh, UBG
 * 18.03.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

import {UbgAnswer} from '../../../../model/answer';
import {UbgCampaign} from '../../../../model/campaign';
import {UbgQuestion} from '../../../../model/question';
import {UbgQuestionService} from '../../../../services/question.service';
import {UbgAutocompleteOption} from '../../../../../ubg_components/autocomplete/model/option';

@Component({
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss'],
})
export class UbgAnswerMoveDialog implements OnInit {
  
  isLoading: boolean = false
  isProcessing: boolean = false
  questionOptions: Array<UbgAutocompleteOption>
  disabled: boolean = false
  
  constructor(private dialogRef: MatDialogRef<UbgAnswerMoveDialog>,
    @Inject(MAT_DIALOG_DATA) private data: {
      answer: UbgAnswer,
      campaign: UbgCampaign
      question: UbgQuestion
      selectedQuestionId: string
    },
    private question: UbgQuestionService) {
    this.questionOptions = [];
  }
  
  ngOnInit() {
    this.loadQuestions();
  }
  
  loadQuestions() {
    this.isLoading = true;
    this.question.getOptions(this.data.campaign).then((response: {
      options: Array<UbgAutocompleteOption>
    }) => {
      this.questionOptions = response.options;
      this.isLoading = false;
    });
  }
  
  questionSelected(event: string) {
    this.data.selectedQuestionId = event;
  }
 
  
}