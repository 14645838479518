import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';

import {UbgContentaModule} from '../contenta/contenta.module';

import {ContentaUserLoginComponent} from './components/login/login.component';
import {ContentaUserRegisterComponent} from './components/register/register.component';
import {ContentaUserResetComponent} from './components/reset/reset.component';
import {ContentaUserDialogPasswortComponent} from './components/dialog/password/password';
import {ContentaUserDialogForgotComponent} from './components/dialog/forgot/forgot';
import {ContentaUserDialogCduplusComponent} from './components/cduplus/cduplus.component';
import {ContentaUserSidebarComponent} from './components/sidebar/sidebar.component';
import {ContentaUserPasswordComponent} from './components/password/password.component';

const userRoutes: Routes = [
  {
    path: 'user/reset/:userId/:timestamp/:hash',
    component: ContentaUserResetComponent
  },
  {
    path: 'cduplus/auth',
    component: ContentaUserDialogCduplusComponent,
  },
  {
    path: 'user/password',
    component: ContentaUserPasswordComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      userRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCardModule,
    UbgContentaModule,
  ],
  declarations: [
    ContentaUserLoginComponent,
    ContentaUserRegisterComponent,
    ContentaUserResetComponent,
    ContentaUserDialogPasswortComponent,
    ContentaUserDialogForgotComponent,
    ContentaUserDialogCduplusComponent,
    ContentaUserSidebarComponent,
    ContentaUserPasswordComponent,
  ],
  exports: [
    ContentaUserLoginComponent,
    ContentaUserRegisterComponent,
    ContentaUserSidebarComponent,
  ],
  entryComponents: [
    ContentaUserDialogPasswortComponent,
    ContentaUserDialogForgotComponent
  ],
})
export class ContentaUserModule {

  constructor() {}

}