/**
 * Frage-Komponent
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

import {ActivatedRoute} from '@angular/router';

import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';
import {MatButtonToggleChange} from '@angular/material/button-toggle';

import {UbgFilteredQuestion} from '../../model/filteredquestion';
import {UbgQuestionService} from '../../services/question.service';
import {UbgQuestion} from '../../model/question';
import {UbgCategory} from '../../model/category';
import {UbgQuestionCategoryDialog} from './dialogs/category/category.component';
import {UbgCategoryService} from '../../services/category.service';
import {UbgAnswer} from '../../model/answer';
import {UbgAnswerService} from '../../services/answer.service';
import {UbgAuthorService} from '../../services/author.service';
import {UbgAuthor} from '../../model/author';
import {UbgCampaign} from '../../model/campaign';
import {UbgQuestionAssignDialog} from './dialogs/assign/assign.component';
import {UbgUserService} from '../../services/user.service';
import {UbgUser} from '../../model/user';
import {UbgAnswerDialog} from './dialogs/answer/answer.component';
import {UbgStatusService} from '../../services/status.service';
import {UbgStatus} from '../../model/status';
import {UbgCampaignService} from '../../services/campaign.service';
import {UbgQuestionPrintDialog} from '../dialogs/print/print.component';

@Component({
  selector: 'ubg-question',
  templateUrl: './question.component.html',
  styleUrls: ['question.component.scss'],
})
export class UbgQuestionComponent implements OnInit {
  
  @Input()filteredQuestion: UbgFilteredQuestion
  @Input()campaign: UbgCampaign
  @Output()moved = new EventEmitter();
  question: UbgQuestion
  isLoaded: boolean = false
  isQuerying: boolean = false
  isOpen: boolean = false
  loadCounter: number = 0
  class: string = 'closed'
  categories: string = '...'
  answers: {
    gliederung: Array<UbgAnswer>
    person: Array<UbgAnswer>
  }
  assignedCounter: number = 0;
  
  constructor(private route: ActivatedRoute,
                private dialog: MatDialog,
                private snackbar: MatSnackBar,
                private service: UbgQuestionService,
                private category: UbgCategoryService,
                private answer: UbgAnswerService,
                private author: UbgAuthorService,
                private user: UbgUserService,
                private status: UbgStatusService,
                private campaignService: UbgCampaignService) {
    this.answers = {
      gliederung: [],
      person: [],
    };
  }
  
  ngOnInit() {
    if (this.campaign === null || this.campaign === undefined) {
      console.error('Keine Kampagne wurde übergenen');
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', null, {
        duration: 8000,
      });
    }
    this.route.queryParams.subscribe((params: any) => {
      let newOpen: boolean = false;
      for (let key in params) {
        if (key.match(/^search/)) {
          newOpen = true;
        }
      }
      if (newOpen) {
        this.open();
      }
      else {
        this.close();
      }
    });
  }
  
  loadQuestion() {
    let promise = new Promise((resolve, reject) => {
      let loadTimestamp = new Date().getTime();
      this.service.get(this.filteredQuestion.id).then((response: UbgQuestion) => {
        this.isQuerying = false;
        this.question = response;
        this.isLoaded = true;
        let currentTimestamp = new Date().getTime();
        let queryTime = currentTimestamp - loadTimestamp;
        if (queryTime > 1000) {
          console.warn('Frage zu laden dauert lange', queryTime, 'ms');
        }
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  open() {
    this.isOpen = true;
    if (this.isLoaded) {
      this.refresh();
      this.loadAnswers();
    }
    else {
      this.isQuerying = true;
      this.loadQuestion().then(() => {
        this.refresh();
        this.loadAnswers();
      }).catch(() => {
        this.loadCounter++;
        setTimeout(() => {
          this.open();
        }, this.loadCounter * 100);
      });
    }
  }
  
  close() {
    this.isOpen = false;
  }
  
  refresh() {
    let categoryLabels: Array<string> = [];
    for (let delta in this.question.relationships.kategorie.data) {
      if (this.question.relationships.kategorie.data[delta].attributes === undefined) {
        this.question.relationships.kategorie.data[delta].attributes = {
          name: '...',
        };
        this.category.get(this.question.relationships.kategorie.data[delta].id).then((response: UbgCategory) => {
          this.question.relationships.kategorie.data[delta] = response;
          this.refresh();
        }).catch(() => {
          this.refresh();
        });
      }
      else {
        categoryLabels.push(this.question.relationships.kategorie.data[delta].attributes.name);
      }
    }
    this.categories = categoryLabels.join(' | ');
  }
  
  editCategory() {
    let data = {
      question: this.question,
    };
    const dialogRef = this.dialog.open(UbgQuestionCategoryDialog, {
      data: data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.service.update(data.question).then((response: UbgQuestion) => {
        this.question = response;
        this.refresh();
      }).catch(() => {
        this.snackbar.open('Die Kategorien können nicht aktualisiert werden', '', {
          duration: 8000,
        });
        this.loadQuestion();
      })
    });
  }
  
  loadAnswers() {
    this.answers = {
      gliederung: [],
      person: [],
    };
    for (let i in this.filteredQuestion.answers) {
      if (this.filteredQuestion.answers[i].relationships.autor.data.attributes.person) {
        this.answers.person.push(this.filteredQuestion.answers[i]);
      }
      else {
        this.answers.gliederung.push(this.filteredQuestion.answers[i]);
      }
    }
  }
  
  printDialog() {
    let data = {
      kommentare: true,
      namen: true,
    };
    this.dialog.open(UbgQuestionPrintDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        const comments = data.kommentare ? 1 : 0;
        const names = data.namen ? 1 : 0;
        this.print(comments, names);
      }
    });
  }
  
  print(comments: number, names: number) { 
    this.service.print(this.campaign, this.question, comments, names).then((response: any) => {
      let fileName = 'frage.pdf';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000,
      })
    });
  }
  
  assign() {
    let data = {
      question: this.question,
      campaign: this.campaign,
      searchString: '',
      users: [],
    };
    this.dialog.open(UbgQuestionAssignDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        this.isQuerying = true;
        this.assignedCounter = 0;
        this.user.setAnswerAssign(this.campaign.id, this.question.relationships.antworten.data, data.users).then(() => {
          this.isQuerying = false;
        });
        
//        for (let i in this.question.relationships.antworten.data) {
//          this.user.setAnswerAssign(this.campaign.id, this.question.relationships.antworten.data[i].id, data.userId).then(() => {
//            this.assignedCounter++;
//            if (this.assignedCounter == this.question.relationships.antworten.data.length) {
//              this.isQuerying = false;
//            }
//          });
//        }
      }
    });
  }
  
  addAnswer() {
    let autor = new UbgAuthor;
    autor.attributes = {
      title: '',
      name: '',
      person: null,
    };
    autor.relationships = {}
    let data: {
      autor: UbgAuthor
      text: string
    } = {
      autor: autor,
      text: null,
    };
    this.dialog.open(UbgAnswerDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        this.getAuthor(data.autor).then((author: UbgAuthor) => {
          let answer = new UbgAnswer;
          answer.relationships = {
            autor: {
              data: author,
            },
            status: null,
          };
          answer.attributes = {
            text: {
              value: data.text,
            },
            timestamp: Math.round(new Date().getTime() / 1000),
          };
          this.status.getByName('Offen').then((status: UbgStatus) => {
            answer.relationships.status = {
              data: status
            };
            this.answer.create(answer).then((answer: UbgAnswer) => {
              this.question.relationships.antworten.data.push(answer);
              this.service.update(this.question).then((question: UbgQuestion) => {
                this.campaignService.refresh(this.campaign.id, {
                  text: '',
                  tag: '',
                }).then((response: UbgCampaign) => {
                  for (let i in response.filteredQuestions) {
                    if (response.filteredQuestions[i].id == this.question.id) {
                      this.filteredQuestion = response.filteredQuestions[i];
                      this.open();
                    }
                  }
                });
              });
            }).catch(() => {
              this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Code: QN-317', '', {
                duration: 8000,
              });
            });
          })
        });
      }
    });
  }
  
  private getAuthor(author: UbgAuthor) {
    let promise = new Promise((resolve, reject) => {
      if (author.id === undefined) {
        author.attributes.name = author.attributes.title;
        this.author.create(author).then((response: UbgAuthor) => {
          resolve(response);
        });
      }
      else {
        resolve(author);
      }
    });
    return promise;
  }
  
  changeSort(event: MatButtonToggleChange) {
    let newAnswers = {
      gliederung: [],
      person: []
    };
    for (let key in this.answers) {
      let rawAnswers: Array<UbgAnswer> = JSON.parse(JSON.stringify(this.answers[key]));
      newAnswers[key] = rawAnswers.sort((a, b) => {
        let aData: string;
        let bData: string
        if (event.value.match(/^abc/)) {
          aData = a.relationships.autor.data.attributes.title;
          bData = b.relationships.autor.data.attributes.title;
        }
        else if (event.value.match(/^date/)) {
          aData = a.attributes.timestamp.toString();
          bData = b.attributes.timestamp.toString();
        }
        if (event.value.match(/asc$/)) {
          if (aData > bData) {
            return 1;
          }
          else if (aData < bData) {
            return -1;
          }
        }
        if (event.value.match(/desc$/)) {
          if (aData > bData) {
            return -1;
          }
          else if (aData < bData) {
            return 1;
          }
        }
        return 0;
      });
    }
    this.answers = newAnswers;
  }
  
  delete(answer: UbgAnswer): void {
    this.isQuerying = true;
    this.close();
    this.service.get(this.filteredQuestion.id).then((response: UbgQuestion) => {
      let newAnswers: Array<UbgAnswer> = [];
      for (let i in response.relationships.antworten.data) {
        if (response.relationships.antworten.data[i].id !== answer.id) {
          newAnswers.push(response.relationships.antworten.data[i]);
        }
      }
      response.relationships.antworten.data = newAnswers;
      this.service.update(response).then(() => {
        this.answer.get(answer.id).then((answerToUpdate: UbgAnswer) => {
          answerToUpdate.attributes.ist_geloscht = true;
          this.answer.update(answerToUpdate).then(() => {
            let newFilteredAnswers: Array<UbgAnswer> = [];
            for (let i in this.filteredQuestion.answers) {
              if (this.filteredQuestion.answers[i].id !== answer.id) {
                newFilteredAnswers.push(this.filteredQuestion.answers[i]);
              }
            }
            this.filteredQuestion.answers = newFilteredAnswers;
            this.loadQuestion();
            this.loadAnswers();
          });
        });
      });
    });
  }
  
}