/**
 * Kategorie-Dialog
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';

import {UbgQuestion} from '../../../../model/question';
import {UbgCategory} from '../../../../model/category';
import {UbgCategoryService} from '../../../../services/category.service';

@Component({
  templateUrl: './category.component.html',
})
export class UbgQuestionCategoryDialog {
  
  newCategory: string = ''
  
  constructor(private dialogRef: MatDialogRef<UbgQuestionCategoryDialog>,
    @Inject(MAT_DIALOG_DATA) private data: {
      question: UbgQuestion,
    },
    private snackbar: MatSnackBar,
    private service: UbgCategoryService) {}
  
  addCategory() {
    this.service.getByTag(this.newCategory).then((response: any) => {
      if (response === false) {
        this.service.create(this.newCategory).then((response: UbgCategory) => {
          this._addCategoryToQuestion(response);
        }).catch(() => {
          setTimeout(() => {
            this.addCategory();
          }, 100);
        });
      }
      else {
        this._addCategoryToQuestion(response);
      }
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000,
      })
    });
  }
  
  private _addCategoryToQuestion(tag: UbgCategory) {
    let tagFound: boolean = false;
    for (let i in this.data.question.relationships.kategorie.data) {
      if (this.data.question.relationships.kategorie.data[i].id == tag.id) {
        tagFound = true;
      }
    }
    if (!tagFound) {
      this.data.question.relationships.kategorie.data.push(tag);
    }
  }
  
  removeCategory(tag: UbgCategory) {
    let newCategories: Array<UbgCategory> = [];
    for (let i in this.data.question.relationships.kategorie.data) {
      if (this.data.question.relationships.kategorie.data[i].id !== tag.id) {
        newCategories.push(this.data.question.relationships.kategorie.data[i]);
      }
    }
    this.data.question.relationships.kategorie.data = newCategories;
  }
  
}