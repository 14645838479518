/**
 * Antwort-Komponent
 * 
 * Attila Németh, UBG
 * 27.02.2019
 */
 
import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

import {MatDialog, MAT_DIALOG_DATA} from '@angular/material';
import {MatSnackBar} from '@angular/material';

import {UbgAnswer} from '../../model/answer';
import {UbgAnswerService} from '../../services/answer.service';
import {UbgStatusService} from '../../services/status.service';
import {UbgStatus} from '../../model/status';
import {UbgAuthor} from '../../model/author';
import {UbgAuthorService} from '../../services/author.service';
import {UbgAnswerEditDialog} from './dialogs/edit/edit.component';
import {UbgTag} from '../../model/tag';
import {UbgTagService} from '../../services/tag.service';
import {UbgBearbeiter} from '../../model/bearbeiter';
import {UbgBearbeiterService} from '../../services/bearbeiter.service';
import {UbgComment} from '../../model/comment';
import {UbgCommentService} from '../../services/comment.service';
import {UbgAnswerAssignDialog} from './dialogs/assign/assign.component';
import {UbgAnswerMoveDialog} from './dialogs/move/move.component';
import {UbgCampaign} from '../../model/campaign';
import {UbgQuestion} from '../../model/question';
import {UbgQuestionService} from '../../services/question.service';
import {UbgUserService} from '../../services/user.service';
import {UbgAnswerLogDialogComponent} from './dialogs/log/log.component';
import {UbgQuestionPrintDialog} from '../dialogs/print/print.component';
import {ContentaUserService} from '../../../contenta_user/services/contenta_user.service';
import {UbgAnswerDeleteDialog} from '../dialogs/delete/delete.component';

@Component({
  selector: 'ubg-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['answer.component.scss'],
})
export class UbgAnswerComponent implements OnInit {
  
  @Input()answer: UbgAnswer
  @Input()campaign: UbgCampaign
  @Input()question: UbgQuestion
  @Output()moved = new EventEmitter();
  @Output()deleted = new EventEmitter();
  class: string = ''
  authorClass: string = ''
  authorName: string= ''
  statusText: string = ''
  tags: string = ''
  bearbeiterName: string = ''
  isOpen: boolean = true
  isLoading: boolean = false
  canDelete: boolean = false
  
  constructor(private snackbar: MatSnackBar, 
                private dialog: MatDialog,
                private status: UbgStatusService,
                private service: UbgAnswerService,
                private author: UbgAuthorService,
                private tag: UbgTagService,
                private bearbeiter: UbgBearbeiterService,
                private comment: UbgCommentService,
                private qService: UbgQuestionService,
                private router: Router,
                private user: UbgUserService,
                private contentaUser: ContentaUserService) {
  }
  
  ngOnInit() {
    this.refreshProperties();
    if (this.campaign === null || this.campaign === undefined) {
      console.error('Keine Kampagne wurde übergenen');
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', null, {
        duration: 8000,
      });
    }
    if (this.question === null || this.question === undefined) {
      console.error('Keine Frage wurde übergenen');
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', null, {
        duration: 8000,
      });
    }
    if (this.contentaUser.hasPermission('delete ubg_campaign_answer')) {
      this.canDelete = true;
    }
  }
  
  refreshProperties() {
    this.refreshAuthor();
    this.service.get(this.answer.id).then((response: UbgAnswer) => {
      this.answer = response;
      this.refreshClass();
      this.refreshTags();
      this.refreshBearbeiter();
    })
  }
  
  private refreshBearbeiter() {
    let bearbeiter: Array<string> = [];
    if (this.answer.relationships.bearbeiter.data.length > 0) {
      for (let delta in this.answer.relationships.bearbeiter.data) {
        if (this.answer.relationships.bearbeiter.data[delta].attributes === undefined) {
          this.bearbeiter.get(this.answer.relationships.bearbeiter.data[delta].id).then((response: UbgBearbeiter) => {
            this.answer.relationships.bearbeiter.data[delta] = response;
            this.refreshBearbeiter();
          });
        }
        else {
          bearbeiter.push(this.answer.relationships.bearbeiter.data[delta].attributes.name);
        }
      }
    }
    this.bearbeiterName = bearbeiter.join(' | ');
  }
  
  private refreshClass() {
    let classes: Array<string> = [];
    if (this.answer.relationships.status.data === null) {
    }
    else if (this.answer.relationships.status.data.attributes === undefined) {
      this.status.get(this.answer.relationships.status.data.id).then((response: UbgStatus) => {
        this.answer.relationships.status.data = response;
        this.refreshClass();
      });
    }
    else {
      this.statusText = this.answer.relationships.status.data.attributes.name;
      classes.push('status-name-' + this.answer.relationships.status.data.attributes.name);
    }
    if (this.isOpen) {
      classes.push('open');
    }
    this.class = classes.join(' ');
  }
  
  private refreshAuthor() {
    if (this.answer.relationships.autor.data.attributes === undefined) {
      this.author.get(this.answer.relationships.autor.data.id).then((response: UbgAuthor) => {
        this.answer.relationships.autor.data = response;
        this.refreshAuthor();
      });
      this.authorName = '...';
    }
    else {
      this.authorName = this.answer.relationships.autor.data.attributes.title;
      if (this.answer.relationships.autor.data.attributes.person) {
        this.authorClass = 'author-person';
      }
      else {
        this.authorClass = 'author-gliederung';
      }
    }
  }
  
  private refreshTags() {
    let tags: Array<string> = [];
    if (this.answer.relationships.tags.data.length > 0) {
      for (let delta in this.answer.relationships.tags.data) {
        if (this.answer.relationships.tags.data[delta].attributes === undefined) {
          this.tag.get(this.answer.relationships.tags.data[delta].id).then((response: UbgTag) => {
            this.answer.relationships.tags.data[delta] = response;
            this.refreshTags();
          });
        }
        else {
          tags.push(this.answer.relationships.tags.data[delta].attributes.name);
        }
      }
    }
    this.tags = tags.join(' | ');
  }
  
  open() {
    this.isOpen = true;
    this.refreshClass();
  }
  
  
  close() {
    this.isOpen = false;
    this.refreshClass();
  }
  
  toggle() {
    if (this.isOpen) {
      this.isOpen = false;
    }
    else {
      this.isOpen = true;
    }
    this.refreshClass();
  }
  
  setStatus(status: string) {
    this.isLoading = true;
    this.status.getByName(status).then((response: any) => {
      // Response: UbgStatus oder FALSE
      if (response === false) {
        // So einen Status gibt's noch nicht.
        this.status.create(status).then((response: UbgStatus) => {
          this.answer.relationships.status.data = response;
          this.update();
        }).catch((error) => {
          console.error('Fehler beim Status Erstellen', error);
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
            duration: 8000
          });
          this.isLoading = false;
        });
      }
      else {
        this.answer.relationships.status.data = response;
        this.update();
      }
    }).catch(() => {
      setTimeout(() => {
        this.setStatus(status);
      }, 500);
    });
  }
  
  update() {
    this.service.update(this.answer).then((response: UbgAnswer) => {
      this.answer = response;
      this.isLoading = false;
      this.refreshProperties();
    }).catch((error) => {
      console.error('Fehler beim Aktualisieren', error);
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000
      });
      this.isLoading = false;
    });
  }
  
  edit() {
    let data = {
      answer: this.answer,
      comment: {
        active: false,
        text: ''
      }
    };
    this.dialog.open(UbgAnswerEditDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        this.isLoading = true;
        this.answer = data.answer;
        this.editAddComment(data.comment).then(() => {
          if (data.answer.relationships.status.data.id !== '') {
            this.setStatus(data.answer.relationships.status.data.attributes.name);
          }
          else {
            delete this.answer.relationships.status;
            this.update();
          }
        });
      }
    });
  }
  
  editAddComment(comment: {
    active: boolean
    text: string
  }) {
    let promise = new Promise((resolve, reject) => {
      if (comment.active === false || comment.text === '') {
        resolve();
      }
      else {
        this.comment.create({
          attributes: {
            text: {
              value: comment.text,
              format: 'full_html',
            }
          }
        }).then((response: UbgComment) => {
          this.answer.relationships.kommentare.data.push(response);
          resolve();
        });
      }
    });
    return promise;
  }
  
  printDialog() {
    let data = {
      kommentare: true,
      namen: true,
    };
    this.dialog.open(UbgQuestionPrintDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        const comments = data.kommentare ? 1 : 0;
        const names = data.namen ? 1 : 0;
        this.print(comments, names);
      }
    });
  }
  
  print(comments: number, names: number) { 
    this.service.print(this.campaign, this.answer, comments, names).then((response: any) => {
      let fileName = 'antwort.pdf';
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch(() => {
      this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten', '', {
        duration: 8000,
      })
    });
  }
  
  assign() {
    let data = {
      answer: this.answer,
      campaign: this.campaign,
      searchString: '',
      users: this.answer.relationships.zustandig.data
    };
    this.dialog.open(UbgAnswerAssignDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        this.isLoading = true;
        this.user.setAnswerAssign(this.campaign.id, [this.answer], data.users).then(() => {
          this.service.get(this.answer.id).then((response: UbgAnswer) => {
            this.answer = response;
            this.isLoading = false;
          });
        });
      }
    });
  }
  
  move() {
    let data = {
      answer: this.answer,
      campaign: this.campaign,
      question: this.question,
      selectedQuestionId: null
    };
    this.dialog.open(UbgAnswerMoveDialog, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {
        if (data.selectedQuestionId == data.question.id) {
          // Keine Änderung 
        }
        else {
          this.isLoading = true;
          this.applyMove(this.question.id, data.selectedQuestionId);
        }
      }
    })
  }
  
  /**
   * Die Verschiebung verwenden
   * 
   * Die Antwort soll
   *  - von der aktuellen Frage entfernt
   *  - zur neuen Frage hinzugefügt werden.
   *  Beide sollen gespeichert werden.
   *  Die Seite muss neugeladen werden
   *  Und das alles Asyncron, also .then().then().then().then() :-)
   */
  private applyMove(sourceQuestionId: string, destinationQuestionId: string) {
    this.qService.get(sourceQuestionId).then((sourceQuestion: UbgQuestion) => {
      let newAnswers = [];
      for (let i in sourceQuestion.relationships.antworten.data) {
        if (sourceQuestion.relationships.antworten.data[i].id !== this.answer.id) {
          newAnswers.push(sourceQuestion.relationships.antworten.data[i]);
        }
      }
      sourceQuestion.relationships.antworten.data = newAnswers;
      this.qService.update(sourceQuestion).then(() => {
        this.qService.get(destinationQuestionId).then((destinationQuestion: UbgQuestion) => {
          let newAnswers = [];
          for (let i in destinationQuestion.relationships.antworten.data) {
            newAnswers.push(destinationQuestion.relationships.antworten.data[i]);
          }
          newAnswers.push(this.answer);
          destinationQuestion.relationships.antworten.data = newAnswers;
          this.qService.update(destinationQuestion).then(() => {
            this.snackbar.open('Die Antwort wurde verschoben', null, {
              duration: 5000,
            })
            this.moved.emit();
          });
        });
      });
    });
  }
  
  editDisabled() {
    if (this.answer.relationships.status === null) {
      return true;
    }
    else if (this.answer.relationships.status === undefined) {
      return true;
    }
    else if (this.answer.relationships.status.data === null) {
      return false;
    }
    else if (this.answer.relationships.status.data.attributes === undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  
  showTagLog() {
    let data = {
      entityType: 'ubg_campaign_answer',
      entityId: this.answer.id,
      fieldName: 'tags',
    };
    this.dialog.open(UbgAnswerLogDialogComponent, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {}
    });
  }
  
  showStatusLog() {
    let data = {
      entityType: 'ubg_campaign_answer',
      entityId: this.answer.id,
      fieldName: 'status',
    };
    this.dialog.open(UbgAnswerLogDialogComponent, {
      data: data
    }).afterClosed().subscribe((response: number) => {
      if (response == 1) {}
    });
  }
  
  delete() {
    this.dialog.open(UbgAnswerDeleteDialog).afterClosed().subscribe((response: number) => {
      this.deleted.emit();
    });
  }
  
}