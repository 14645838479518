/**
 * Antwort-Dialog
 * 
 * Attila Németh, UBG
 * 05.06.2019
 */
 
import {Component, Inject, OnInit} from '@angular/core';

import {MAT_DIALOG_DATA} from '@angular/material';
import {MatSelectChange} from '@angular/material/select';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {UbgAuthor} from '../../../../model/author';
import {UbgBundeslandService} from '../../../../services/bundesland.service';
import {UbgBundesland} from '../../../../model/bundesland';
import {UbgAutocompleteOption} from '../../../../../ubg_components/autocomplete/model/option';
import {UbgAuthorService} from '../../../../services/author.service';

@Component({
  templateUrl: './answer.component.html',
})
export class UbgAnswerDialog implements OnInit {
  
  disabled: boolean = true
  authorOptions: Array<UbgAutocompleteOption> = []
  bundeslaender: Array<UbgBundesland> = []
  editorConfig = {
    minHeight: 5,
    toolbar: [
      ['bold', 'italic', 'underline', 'color'],
    ],
  }
  
  constructor(@Inject(MAT_DIALOG_DATA) private data: {
      autor: UbgAuthor
      text: string
    },
    private bundeslandService: UbgBundeslandService,
    private authorService: UbgAuthorService) {
  }
  
  ngOnInit() {
    this.bundeslandService.getAll().then((response: Array<UbgBundesland>) => {
      this.bundeslaender = response;
    });
  }
  
  authorChanged() {
    if (this.data.autor.attributes.title.length > 2) {
      this.authorService.getByName(this.data.autor.attributes.title).then((response: Array<UbgAuthor>) => {
        let newOptions: Array<UbgAutocompleteOption> = [];
        for (let i in response) {
          let label: string = response[i].attributes.title;
          if (response[i].attributes.person == '1') {
            label += ' (' + response[i].attributes.alter + ')';
          }
          newOptions.push({
            id: response[i].id,
            label: label
          });
        }
        this.authorOptions = newOptions;
      });
    }
  }
  
  authorSelected(event: MatAutocompleteSelectedEvent) {
    this.authorService.get(event.option.value).then((response: any) => {
      this.data.autor = response;
      if (response['attributes']['person'] == true) {
        this.data.autor.attributes.person = '1';
      }
      else {
        this.data.autor.attributes.person = '0';
      }
    });
  }
  
  validate() {
    let disabled: boolean = false;
    if (this.data.autor.attributes.title == '') {
      disabled = true;
    }
    if (this.data.autor.attributes.person === null) {
      disabled = true;
    }
    if (this.data.text === null || this.data.text == '') {
      disabled = true;
    }
    this.disabled = disabled;
  }
  
  bundeslandSelected(event: MatSelectChange) {
    for (let i in this.bundeslaender) {
      if (this.bundeslaender[i].id == event.value) {
        this.data.autor.relationships.bundesland = {
          data: this.bundeslaender[i]
        };
      }
    }
  }
  
  getAuthorBundesland() {
    if (this.data.autor.relationships.bundesland === undefined) {
      return null;
    }
    else if (this.data.autor.relationships.bundesland.data === null) {
      return null;
    }
    else {
      return this.data.autor.relationships.bundesland.data.id;
    }
  }
    
}