/**
 * Suche-Elemente-Service
 * 
 * Attila Németh, UBG
 * 01.04.2019
 */
 
import { Injectable } from '@angular/core'; 

@Injectable({
  providedIn: 'root'
})
export class UbgSearchService {
  
  isVisible: boolean = false
  
  constructor() {}
  
  show() {
    this.isVisible = true;
  }
  
  hide() {
    this.isVisible = false;
  }
  
  toggle() {
    if (this.isVisible) {
      this.hide();
    }
    else {
      this.show();
    }
  }
  
}