/**
 * Antwort
 * 
 * Attila Németh, UBG
 * 25.02.2019
 */
 
import {UbgAuthor} from './author';
import {UbgStatus} from './status';
import {UbgComment} from './comment';
import {UbgTag} from './tag';
import {UbgBearbeiter} from './bearbeiter';
import {UbgUser} from './user';
 
export class UbgAnswer {
  
  id: string
  type: string = 'ubg_campaign_answer--ubg_campaign_answer'
  attributes: {
    text: {
      value: string
      format?: string
      processed?: string
    }
    timestamp: number
    ist_geloscht?: boolean
  }
  relationships: {
    autor: {
      data: UbgAuthor
    }
    status: {
      data: UbgStatus
    }
    kommentare?: {
      data: Array<UbgComment>
    }
    tags?: {
      data: Array<UbgTag>
    }
    bearbeiter?: {
      data: Array<UbgBearbeiter>
    }
    zustandig?: {
      data: Array<UbgUser>
    }
  }
  
}